#contact-wrapper {
  width: 103%;
  height: 100%;
  font-size: 10px;

  background: #00aae4;
  box-shadow: 0px 3.15853px 12.6341px rgba(0, 0, 0, 0.25);
}
.invalid-feedback {
  font-size: 0.875rem;
  color: #b30000;
  
}
.formHeader {
  padding: 1rem 1rem 0 1rem;
  align-items: center;
  text-align: center;
}
.colordot7 {
  background-color: #ff9823;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 0;
  margin-top: -2rem;
  margin-right: 3.5rem;
}
.colordot8 {
  background-color: #ff9823;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  right: 0;
  margin-top: -3rem;
  margin-right: 3.5rem;
}
.colordot9 {
  background-color: #ff6261;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  right: 0;
  margin-top: -2rem;
  margin-right: 2rem;
}

.Connect {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
}
.Feedback {
  color: #fff;
  font-size: 1rem;
}
.consent-font {
  color: #fff !important;
  font-weight: 400;
  margin-bottom: 35px !important;
}
.consent-font a:hover {
  color: #2d388f !important;
  text-decoration: underline;
  cursor: pointer;
}
.borrowerLeadGeneration .btn-submit {
  margin: 0px !important;
  background: #083f88;
  color: #fff;
  font-weight: 500;
  border: #083f88;
}

#reference {
  display: none;
}
.consentSmall,
.consentLink,
.form-check-label,
.text-blue,
.btn-blg {
  color: #ffff !important;
}

.form-check {
  font-size: 13px !important;
}

.btn-outline-primary {
  border-color: #fff !important;
}

.borrowerLeadGeneration .form-link {
  text-decoration: underline;
  color: #fff !important;
}
.borrowerLeadGeneration .consent-font a {
  color: #fff !important;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
  #contact-wrapper {
    margin-left: 0rem;
    width: 100%;
    margin-top: 2rem !important;
  }
}
@media screen and (min-width: 850px) and (max-width: 992px) {
  .colordot7,
  .colordot8 {
    margin-right: 11rem !important;
  }
  .colordot9 {
    margin-right: 9rem;
  }
}
@media screen and (min-width: 992px) {
  .mainBtnDiv {
    margin-top: -20px;
  }
  .colordot7,
  .colordot8,
  .colordot9 {
    display: none;
  }
  #contact-wrapper {
    height: 100%;
    font-size: 10px;
    background: #00aae4;
    box-shadow: 0px 3.15853px 12.6341px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 699px) {
  .Connect {
    font-size: 1.5rem !important;
  }
  .Feedback {
    font-size: 1rem !important;
  }
  #contactusform {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1399px) {
  .form-control,
  .form-select {
    font-size: 0.9rem !important;
    color: #525252 !important;
  }
}

@media screen and (max-width: 416px) {
  .borrowerLeadGeneration .btnAdjustDiv {
    left: 30% !important;
  }
}
