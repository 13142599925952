/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

 .realtor-heading {
  line-height: 1.65;
}
.banner,
.banner a {
  background-repeat: no-repeat;
  text-align: center !important;
  color: #ffff;
  position: relative;

  font-family: "poppins";
}
.realtorlogo {
  max-width: 100px;
  margin-bottom: 1rem;
}
.joaquinsec a:hover {
  color: #00aae4;
}

.namejoaqu {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  color: #00aae4;
}

.introtext {
  text-align: justify;
  font-family: "poppins";
  color: #ffff;
}

.contactbutton,
.read-more-btn {
  background: linear-gradient(
    90deg,
    rgba(7, 37, 77, 0.68) 57.16%,
    rgba(7, 37, 77, 0) 94.2%
  );
  border-radius: 5px;
  color: #ffffff;
  border-color: #fff;
  text-decoration: none !important;
}

.contactbutton :hover {
  cursor: pointer;
}

.namejo2 img {
  /* background-color: greenyellow; */
  margin-right: 7px;
}

.namejo2 sup {
  font-size: 1rem !important;
  top: -0.2em !important;
}
.introtext sup {
  font-size: 1rem !important;
  top: -0.2em !important;
}
.read-more-btn {
  margin-bottom: 1rem;
  border-radius: 5px;
  max-width: 120px;
  padding: 5px;
}

@media screen and (min-width: 992px) {
  .btn-flex {
    display: flex;
    justify-content: space-between;
  }
  .subname {
    color: #fff;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .mobilefamily,
  .document-btn {
    display: none;
  }
  .CN15Btn,
  .IABSBtn {
    width: 115px;
  }
  .contactbutton {
    font-size: 15px;
    padding: 5px 10px !important;
    margin-top: 6px !important;
    height: 43px !important;
  }

  .mobilejoq {
    display: none;
  }

  .closebuttnmobile {
    display: none;
  }

  .joaquinsec {
    font-size: 12px;
    text-align: start;
    padding-left: 2rem;
    line-height: 19.5px;
    font-weight: 400;
  }

  .joaquinrow {
    position: relative;
    top: 7rem;
  }

  .edvin {
    width: 75%;
  }

  .namejo2 {
    padding-bottom: 2rem;
  }

  .banner {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/desktopbg.webp");
    padding-bottom: 8rem;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 520px;
    width: 100%;
  }
  .homepage .banner {
    padding-bottom: 3rem;
  }
  .joaquinsec {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/blackbackground.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .namejo2 a {
    text-decoration: underline;
  }

  .realtorNameTop {
    text-align: left !important;
    margin-top: 11rem;
    z-index: 2;
    position: relative;
    font-family: "Poppins" !important;
    color: #ffffff;
  }

  .bannertextdesktop {
    text-align: left !important;
    font-style: normal;
    font-weight: 600;
    border-left: 6px solid #00aae4;
    z-index: 2;
    position: relative;
    font-family: "Poppins" !important;
    color: #ffffff;
  }

  .realtorHeading3 {
    text-align: left !important;
  }

  .realtorHeadingSpacing {
    margin-bottom: 3rem;
  }
  .introtext {
    display: flex;
    flex-direction: column;
  
    height: 100%;
    padding-bottom: 1.5rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.7px) {
  .bannertextdesktop h2,
  .bannertextdesktop h3 {
    font-size: 1.4rem;
  }
  .bannerimage {
    margin-top: -9rem;
  }
  .CN15Btn,
  .IABSBtn {
    width: 100px !important;
  }
  .contactbutton {
    font-size: 13px !important;
    padding: 5px !important;
    margin-top: 6px !important;
    height: 35px !important;
  }
  .introtext {
    padding-bottom: 1.5rem;
  
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399.7px) {
  .bannertextdesktop h2,
  .bannertextdesktop h3 {
    font-size: 1.6rem;
  }
  .introtext {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 1399.9px) {
  .bannertextdesktop h2,
  .bannertextdesktop h3 {
    font-size: 1.9rem !important;
  }
}

@media screen and (max-width: 991.9px) {
  .banner {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/mobilepng.webp");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 215px;
    width: 100%;
  }
  .bannerimage {
    display: none;
  }
  .document-btn {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
  }

  .subname {
    color: #fff;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .CN15Btn,
  .IABSBtn {
    width: 125px;
  }

  .realtorlogo {
    max-width: 100px;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
  .edvin {
    max-width: 200px;
    margin-left: auto;
  }

  .namejo2 {
    text-align: center;
    font-size: 1rem !important;
  }

  .closebuttn {
    display: none;
  }

  .closebuttnmobile {
    padding-bottom: 2rem;
  }

  .mortgageofficer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mobilefamily {
    max-width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .realtorNameTop {
    margin-top: 8.5rem !important;
  }

  .realtorHeadingSpacing {
    margin-bottom: 1rem;
  }

  .realtorHeadingText {
    font-size: 1.5rem;
  }

  .realtorHeadingText1 {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1700px) {
  .banner {
    background-position: center !important;
    padding-top: 4rem;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1699px) {
  .banner {
    padding-bottom: 9rem;
  }
}

.mortgageofficer {
  background: #002b4e;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.mortgageOfficerText {
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: calc(1.275rem + 0.3vw);
}

@media screen and (min-width: 320px) and (max-width: 374.9px) {
  .dotmatrix2 {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 45rem;
    max-width: 30px !important;
  }

  .dotmatrix3 {
    position: absolute;
    left: 0;
    top: 34rem;
    max-width: 30px !important;
  }

  .joaquinrow {
    margin-top: 0rem;
  }
}

@media screen and (min-width: 375px) and (max-width: 424.9px) {
  .dotmatrix2 {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 45rem;
    max-width: 30px !important;
  }

  .dotmatrix3 {
    position: absolute;
    left: 0;
    top: 34rem;
    max-width: 30px !important;
  }

  .joaquinrow {
    margin-top: -3rem;
  }
}

@media screen and (min-width: 425px) and (max-width: 767.9px) {
  .dotmatrix2 {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 45rem;
    max-width: 30px !important;
  }

  .dotmatrix3 {
    position: absolute;
    left: 0;
    top: 34rem;
    max-width: 30px !important;
  }

  .joaquinrow {
    margin-top: -3rem;
  }
}

@media screen and (max-width: 500.7px) {
  .realtorNameTop {
    margin-top: 8.5rem !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .realtorNameTop {
    margin-top: 11rem !important;
  }

  .dotmatrix2 {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 40rem;
    max-width: 40px !important;
  }

  .dotmatrix3 {
    position: absolute;
    left: 0;
    top: 28rem;
    max-width: 40px !important;
  }

  .joaquinrow {
    margin-top: -3rem;
  }

  .mobilefamily {
    margin-top: -0rem;
    max-width: 40%;
  }
}

@media screen and (min-width: 992px) {
  .dotmatrix2 {
    position: absolute;
    /* z-index: -2; */
    right: 0;
    margin-top: 4rem;
    width: 40px;
  }
  .bannerimage {
    width: 100%;
  }
}

.dotmatrix3 {
  position: absolute;
  z-index: 2;
  left: 0;
  margin-top: 20rem !important;
  width: 40px !important;
}

@media screen and (min-width: 992px) and (max-width: 1023.9px) {
  .dotmatrix3 {
    position: absolute;
    z-index: 2;
    left: 0;
    margin-top: 20rem;
    width: 40px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .namejoaqu,
  .edvin,
  .namejo2 {
    margin-left: 0rem;
    padding-right: -0.5rem;
  }

  .namejo2 {
    font-size: 0.6rem;
  }

  .joaquinrow {
    position: relative;
    top: 7rem;
    left: 1rem;
    font-size: 2rem !important;
    padding-right: -5rem;
    /* width: 110%; */
    /* height: 100%; */
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.9px) {
  .introtext {
    font-size: 0.8rem;
    margin-right: 0px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  /* .introtext {
    margin-top: -12rem;
    font-size: 0.92rem;
    margin-right: 0px;
  } */
  .bannerimage {
    margin-top: -12rem;
  }

  .namejo2 img {
    /* background-color: greenyellow; */
    width: 5.5%;
  }
}

.edvin,
.namejoaqu,
.namejo2 {
  margin-left: -8px;
  border-radius: 10px;
}

@media screen and (min-width: 1400px) {
  /* .introtext {
    margin-top: -10rem;
    font-size: 1.1rem;
    margin-right: 0px;
  } */
  .bannerimage {
    margin-top: -12rem;
    margin-left: 1rem;
  }
  .CN15Btn,
  .IABSBtn {
    width: 125px;
  }
  .contactbutton {
    font-size: 16px;
    padding: 5px 12px !important;
    margin-top: 6px !important;
    height: 47px !important;
  }
}

@media screen and (min-width: 2600px) {
  .CN15Btn,
  .IABSBtn {
    width: 125px;
  }
  .contactbutton {
    font-size: 16px;
    padding: 5px 12px !important;
    margin-top: 6px !important;
    height: 47px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .mobilefamily {
    max-width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 374.9px) {
  .realtorNameTop {
    margin-top: 6rem !important;
  }
.bottom-pad{
  padding-bottom:2rem;
}
}