/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

.girlwithboyhouse {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

/* Terms and Conditions Modal Styles */
.terms-modal-body {
  max-height: 60vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  position: relative;
  padding-right: 10px;
  font-size: 16px;
  line-height: 1.5;
}

/* Custom scrollbar for webkit browsers */
.terms-modal-body::-webkit-scrollbar {
  width: 8px;
}

.terms-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.terms-modal-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.terms-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.terms-modal-body ul {
  padding-left: 20px;
  list-style-type: disc;
  margin-bottom: 0;
}

.terms-modal-body li {
  margin-bottom: 10px;
}

.swmc-tru-terms {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.swmc-tru-terms:hover {
  color: #0056b3;
  text-decoration: underline;
}

.terms-conditions-modal .modal-content {
  border-radius: 8px;
}

.terms-conditions-modal .modal-header {
  border-bottom: 1px solid #dee2e6;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 16px;
}

.terms-conditions-modal .modal-title {
  font-size: 20px;
  line-height: 1.2;
}

.terms-conditions-modal .modal-body {
  padding: 16px;
}

.terms-conditions-modal .modal-footer {
  border-top: 1px solid #dee2e6;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
  padding: 12px 16px;
}

.terms-conditions-modal h5 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 1.3;
}

/* Mobile Optimizations */
@media (max-width: 767px) {
  .terms-conditions-modal .modal-dialog {
    margin: 10px;
    width: auto;
    max-width: calc(100% - 20px);
    height: calc(100% - 20px);
    max-height: calc(100% - 20px);
  }
  
  .terms-conditions-modal .modal-content {
    height: 100%;
  }
  
  .terms-modal-body {
    max-height: none;
    font-size: 14px;
    padding-right: 5px;
  }
  
  .terms-conditions-modal .modal-title {
    font-size: 18px;
  }
  
  .terms-conditions-modal h5 {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .terms-conditions-modal .modal-header {
    padding: 12px;
  }
  
  .terms-conditions-modal .modal-footer {
    padding: 10px;
    padding-bottom: 0px !important;
  }
  
  .terms-conditions-modal .modal-body {
    padding: 12px;
  }
  
  .terms-modal-body li {
    margin-bottom: 8px;
  }
  
  .terms-content {
    height: 100%;
    -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  }
}

/* Prevent modal from moving up and down */
.terms-conditions-modal.modal {
  overflow-y: hidden !important;
}

.terms-conditions-modal .modal-dialog {
  height: 80vh;
  max-height: 80vh;
  margin: 1.75rem auto;
  display: flex;
 
}

.terms-conditions-modal .modal-content {
  max-height: calc(80vh - 3.5rem);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.modal-fixed-height {
  height: auto;
  max-height: 80vh;
}

.terms-content {
  overflow-y: auto;
  padding-right: 5px;
  -webkit-overflow-scrolling: touch;
}

/* Button styling */
.terms-conditions-modal .btn-secondary {
  background-color: #007bff;
  border-color: #007bff;
  display: none;
}

.terms-conditions-modal .btn-secondary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

/* Modal close button styling */
.terms-conditions-modal .close {
  font-size: 1.5rem;
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

/* Mobile optimization for modal */
@media (max-width: 767px) {
  .terms-conditions-modal .modal-dialog {
    margin: 0.5rem;
    height: calc(100vh - 1rem);
    max-height: calc(100vh - 1rem);
  }
  
  .terms-conditions-modal .modal-content {
    height: 100%;
    max-height: none;
    border-radius: 4px;
    padding-bottom: 11px !important;
  }
  
  .modal-fixed-height {
    height: 100%;
    max-height: none;
  }
  
  .terms-content {
    height: 100%;
    overflow-y: auto;
  }
  
  /* Ensure the modal takes up available space */
  .terms-conditions-modal.modal-dialog-centered {
    justify-content: flex-start;
    align-items: stretch;
  }
  
  /* Fix button size on mobile */
  .terms-conditions-modal .btn {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }
}

.trueapprovehead {
  color: #2D388F;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  padding: 0 10px;
}

.angelAihead {
  color: #00aae4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  border-left: 6px solid #00aae4;
  padding: 0 10px;
}

.chatWithAngelAiBtn {
  background: linear-gradient(180deg, #093F89 50%, #00AAE4 100%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
}

.chatWithAngelAiBtn:hover {
  background: linear-gradient(180deg, #00AAE4 25%, #093F89 100%);
}

.swmc-tru-terms {
  color: #093f89;
}

.truapprovalVideoLink {
  color: #0ba9e2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
}
.truapprovalVideoLink:hover{
  text-decoration: underline;
  cursor: pointer;
}

.Thoroughlytext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #285798;
  text-align: justify;
  font-size: 1.3rem;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.Thoroughlytextsmall {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #285798;
  text-align: justify;
  font-size: 14px;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.Thoroughlytext1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  color: #093f89;
  text-align: justify;
  font-size: 1.3rem;
  padding-bottom: 11rem;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.hyperLink {
  text-decoration: none;
}

.hyperLink:hover {
  text-decoration: underline;
}


@media screen and (min-width: 992px) and (max-width: 1200px){
  .truapprovalVideoLink {
    font-size: 1rem!important;
  }
  .Thoroughlytext{
    font-size: 1rem!important;
  }

  .dotmatrix{
    width: 19px!important;
  }

  .gettrue{
    font-size: 1rem!important;
  }

  .Thoroughlytext1{
    padding-bottom: 2rem!important;
    font-size: 1rem!important;
  }

  .gettrue a{
    font-size: 1rem!important;
  }

}

@media screen and (min-width: 992px) {
  .mobtext {
    padding-top: 3rem;
  }
  .girlwithboyhouse {
    margin-top: 1rem;
  }

  .gettrue{
    line-height: 1.8rem;
  }
  .dotmatrix {
    position: absolute;
    z-index: -2;
    right: 0;
    margin-top: 4rem;
    width: 40px;
  }

  .dotmatrix1 {
    position: absolute;
    z-index: -2;
    left: 0;
    margin-top: 4rem;
    width: 40px;
  }

  .animatedmobilesection {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/blueshadowimg.webp");
    background-repeat: no-repeat;
    background-size: 100% 70%;
    padding-bottom: 2rem;
  }

  .mmobilegif {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -2rem;
    left: 2rem;
    transform: rotate(7.46deg);
  }
}
.gettrue {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #093f89;
  text-align: justify;
  font-size: 1.3rem;
  
}

.gettrue a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #093f89;
  text-align: justify;
  font-size: 1.3rem;
}

@media screen and (max-width: 991px) {
  .trueapprovehead,
  .angelAihead {
    margin-top: 3rem;
  }
  .dotmatrix1 {
    display: none;
  }
  .dotmatrix {
    display: none;
  }
  .animatedmobilesection {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/mobilebueshadow.webp");
    background-repeat: no-repeat;
    background-size: 100% 85%;
    padding-bottom: 2rem;
  }
  .mmobilegif {
    width: 55%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    border-radius: 32px;
    margin-right: auto;
    position: relative;
    top: 1rem;

    transform: rotate(7.46deg);
  }

  .mbsect {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 992px) and (max-width: 1399px){
  .Thoroughlytext1 {
    padding-bottom:4rem;
  }
}
