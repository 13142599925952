.navbar {
  background: transparent;
  z-index: 999;
  box-shadow: none;
  height: 65px !important;
  top: 0;
  transition: all .3s ease-in;

}
.navbar.active {
  background: #083f88!important;
}
.angel-operator1{
  position: absolute;
  margin-top: -21px;
  margin-left: -34px;
 }
 .contactbutt{
  background: linear-gradient(to right, #31B79F 0%, #74D4F4 50%, #FF6102 100%);
  color: #fff !important;
  border-radius: 5px;
  text-align: center;
  width: 135px;
  height: 41px;
  position: relative;
  padding-top: 1px;
 }
.navbar-dark .navbar-nav .nav-link:hover,
/* .nav-link:focus, */
.nav-link:active {
  color: #ff541c !important;

}

.dropdown-item {
  color: var(--bs-dropdown-link-color) !important;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-toggler-icon-bg: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAAXNSR0IArs4c6QAAAItJREFUSEtjZKADYKSDHQyjlpAUyvDg+j9NUY+BkamDgYGBiyQTsCv+xsD4u4Qx49E1kDTCkhnKuQwMDJOoYAHEiP//Yxgz7y1FtaSBgYlBXNGEgeE/FXzC+I3h5f0zjA0M/1AsoZoPsBg0GicEQnc0TkhNfqP5hIQQGy27SAgsNKWj1S9JYUeX4AIA6DpYGq4jXR8AAAAASUVORK5CYII=);
}

.brandTagLine {
  cursor: pointer;
}
.contactbutt:hover {
  /* background-color: aqua; */
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .contactbutt {
    background-color: #fff;
    color: #fff !important;
    border-radius: 5px;
    margin-left: 10px;
  }
  .contactbutt:hover{
    text-decoration: none!important;
  }
  .navbar-nav {
      margin-top: -10px;
  }
  .navbar-dark .navbar-nav .nav-link:hover,
  /* .nav-link:focus, */
  .nav-link:active, .dropdown-item:hover{
      transform: scale(1.1) !important;      

  }
  .dropdown-menu{
      margin-left: -25px;
  }
  .navbar-dark .navbar-nav .nav-link {
      margin-bottom: -20px;
      margin: 0px 10px -20px 10px;
  }

  .nav-right {
      margin-left: auto;
      /* Pushes the nav items to the right */
  }

 
  .brandTagLine {
      margin-top: 28px !important;
      font-size: 9.5px !important;
      margin-left: -115px !important;
  }

  .contact-us-btn {
      background-image: url("https://d2w24n4g34usfg.cloudfront.net/CustomAngel/images/angelai-contact-btn.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 24px 20px 29px 40px !important;
      height: 50px;
      width: 130px;
      margin-top: -18px !important;
      margin-left: 22px;
  }

  .contact-us-btn:hover {
      transform: scale(1.1);
      color: #FFF !important;
      font-weight: 600 !important;
  }

  .brandTagLineContainerMobile {
      display: none;
  }

  .menu-logos {
      display: none;
  }

  .navbar-nav .nav-link:nth-last-child(1),
  .navbar-nav .nav-link:nth-last-child(2),
  .navbar-nav .nav-link:nth-last-child(3) {
      border-right: none;
      padding-right: 0 !important;
  }

  .navbar-dark .navbar-nav .nav-link {
      color: #ffffff;
  }
}

.navbar-brand a {
  text-decoration: none;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #f26f24;
}

@media only screen and (min-width: 320px) and (max-width: 359.7px) {
  .main_logo {
      width: 140px !important;
      margin-left: -5px;
  }

  .brandTagLine {
      margin-left: -97px !important;
      margin-top: 28px !important;
      font-size: 9.5px !important;
  }
}

@media screen and (max-width: 991px) {

 
  .dropdown-menu.show {
      margin-top: -14px; /* Adjust this value to move the submenu up */
    }
  .navbar-toggler {
      margin-top: -30px
  }

  .navbar-container {
      margin-top: 10px;
  }

  .brandTagLineContainerMobile {
      position: relative;
  }

  .brandTagLine {
      margin-top: 32px;
      font-size: 10px;
      margin-left: -125px;
      padding-bottom: 10px;
  }

  .contact-us-btn {
      /* background-color: #ff541c !important; */
      border: 1px solid #ff541c !important;
      color: #fff !important;
      border-radius: 5px !important;
      padding: 7px 20px 5px 20px !important;
      margin-bottom: 5px;
      width: max-content;
  }
  .contact-us-btn {
      margin-top: 1rem;
    }

  .navbar {
      padding-top: 0;
  }

  .dropdown-menu {
      border: none;
      font-size: 13px;
  }

  .menu-logos {
      margin-right: 10px;
  }

  .dropdown-item:hover,
  .dropdown-item:active {
      background-color: #FFF !important;
      color: #ff541c !important;
  }

  .navbarTransition {
      transform: translateY(-100%);
      transition: all .3s ease-in;
  }

  .ContainerMobile {
      position: relative;
      z-index: 1;
  }

  /* .brandTagLine {
    display: none;
  } */
  .contact-us-btn {
      color: #FFF !important;
      font-weight: 500 !important;
  }

  .navbar-collapse {
      background-color: #fff;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      padding: 1rem;
      text-align: left;
      border-radius: 10px;
      border: 1px solid #ff541c;
      margin-top: -1.3rem;
  }

  .main_logo {
      width: 165px;
      margin-top: -10px;
  }

  .brandTagLineMobile {
      margin-top: 4rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
      background-color: transparent;
      border: none;
      color: #ff541c;
      font-weight: 600;
  }

  .brandTagLineMobile:hover {
      background-color: #ff541c;
      border: none;
  }

  .navbar-dark .navbar-nav .nav-link,
  .dropdown {
      color: #000 !important;
      border-bottom: 1px dashed #FFB79F;
      text-align: left !important;

  }

  /* Remove border-bottom for the last two nav-links */
  .navbar-dark .navbar-nav .nav-link:nth-last-child(-n+1) {
      border-bottom: none;
  }

}

@media screen and (min-width: 359.7px) and (max-width: 767px) {
  .brandTagLine {
      margin-top: 28px !important;
      font-size: 9.5px !important;
      margin-left: -115px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .main_logo {
      width: 180px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-dark .navbar-nav .nav-link {
   font-size: 13px!important;
}
  .brandTagLine {
      margin-top: 50px !important;
      font-size: 9px !important;
      margin-left: -98px !important;
  }

  .dropdown-menu {
      margin-top: 20px !important;
      text-align: center;
  }

  .dropdown-item {
      color: #ffffff;
      background-color: none !important;
  }

  .dropdown-item:active,
  .dropdown-item:hover {
      background-color: transparent;
      color: #ff541c;
  }

    .main_logo {
      width: 140px;
      margin-top: 15px;
  }


  .brandTagLine {
      font-size: 10px;
      margin-top: -8px;
  }

  .brandTagLine {
      font-size: 11px;
      margin-top: -8px;
  }
}

@media screen and (min-width: 1200px) {
  .brandTagLine {
      margin-top: 53px !important;
      font-size: 11px !important;
      margin-left: -138px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .dropdown-menu {
      margin-top: 20px !important;
      text-align: center;
  }

  .dropdown-item {
      color: #ffffff;
      background-color: none !important;
  }

  .dropdown-item:active,
  .dropdown-item:hover {
      background-color: transparent;
      color: #ff541c;
  }


  .main_logo {
      width: 180px !important;
      margin-top: 2px;
  }

  
  .brandTagLine {
      font-size: 10px !important;
      margin-top: 47px !important;
      margin-left: -122px !important;
      margin-bottom: 7px;
  }
}

@media screen and (min-width: 1400px) {
  .dropdown-menu {
      margin-top: 15px !important;
      text-align: center;
  }

  .dropdown-item {
      color: #000;
      background-color: none !important;
  }

  .dropdown-item:active,
  .dropdown-item:hover {
      background-color: transparent;
      color: #ff541c;
  }

  .main_logo {
      width: 170px;
  }

    .brandTagLine {
      font-size: 10px !important;
      margin-top: 39px !important;
      margin-bottom: 4px;
      margin-left: -115px !important;
  }

}