.disclaimerText {
    color: #000;
    text-align: justify;
    font-family: 'Poppins';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

@media screen and (max-width: 991.7px) {
    .disclaimerText {
        font-size: 11px;
    }
}

@media screen and (max-width: 500.7px) {
    .disclaimer {
        padding-bottom: 5rem;
    }

}