/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
 #rigvedQuotesContainer {
  color: #000;
  border: 1px solid #000;
  border-radius: 12px;
  background-color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  width: 300px;
  position: absolute;
  top: 98%;
 z-index: 9999;
 margin-top: 0.5rem;
}
.close {
  cursor: pointer;
  width: 20px;
    height: 20.5px;
    margin: -10px -10px 0 auto;
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
}
.close-div {
  text-align: end;
}
#rigvedQuotesContainer p {
  font-size: 12px;
}

@media screen and (min-width: 320px) and (max-width: 349.7px) {
  #rigvedQuotesContainer {
    left: 3% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374.7px) {
  #rigvedQuotesContainer {
    top: 100% !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 474px) {
  #rigvedQuotesContainer {
  left: 13%!important;
  }
}
@media screen and (min-width: 475px) and (max-width: 575px) {
  #rigvedQuotesContainer {
  left: 19%!important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  #rigvedQuotesContainer {
  left: 23%!important;
  }
}
@media screen and (min-width: 768px) and (max-width: 799.7px) {
  #rigvedQuotesContainer {
    top: 93% !important;
    left: 30% !important;
  }
}


@media screen and (min-width: 800px) and (max-width: 991.7px) {
  #rigvedQuotesContainer {
    top: 91% !important;
    left: 30% !important;
  }
}

@media screen and (max-width: 991.7px) {
  #rigvedQuotesContainer {
    margin-left: auto;
    margin-right: auto;
    left: 10%;
  }
}

@media screen and (min-width: 1400px) {
  #rigvedQuotesContainer {
    top: 95% !important
  }
}


@media screen and (min-width: 1700px) and (max-width: 2000px) {
  #rigvedQuotesContainer {
    left: 15%;
  }
  
}


@media screen and (min-width: 2001px) and (max-width: 2400px)  {
  #rigvedQuotesContainer {
    left: 18%;
  }
}
@media screen and (min-width: 2401px) and (max-width: 2900px)  {
  #rigvedQuotesContainer {
    left: 24%;
  }
}
@media screen and (min-width: 992px) {
  .close-div {
    display: none;
  }
}