@media screen and (min-width: 992px) {
  .footer {
    background-color: #110f20;
    color: #fff;
    padding-top: 0rem !important;
    font-weight: 100 !important;
    font-size: 14px;
    margin-top: -5px !important;
    padding-top: 3rem!important;
  }
}

@media screen and (max-width: 991.7px) {
  .footer {
    background-color: #110f20;
    color: #fff;
    font-weight: 100 !important;
    font-size: 14px;
    padding-top: 2rem !important;
    margin-top: -7px !important;
  }
  .modal-dialog .modal-footer{
    padding-bottom: 3rem !important;
  }
}

.footer-para {
color: #FFFF;
}
.copyright-mobile{
  color: #FFFF;
}
.copyright{
  color: #FFFF;
}
.footer-head{
  color: #FFFF;
}
